$(function () {
    if (_detectSafari()) {
        $('body').addClass('safari');
    }
    $.getJSON("/api/match/topList", data => {
        if (data.error === "Matches not found") {
            TweenMax.set('.s-1__daily-rate', {
                autoAlpha: 0
            });
            return
        }
        let gameObj = data[0];
        headerRate(gameObj.mid, gameObj.away, gameObj.awayLogo, gameObj.home, gameObj.homeLogo, gameObj.kickoff, gameObj.league, gameObj.odd1, gameObj.odd2, gameObj.oddx);
    });
    $.getJSON("/api/match/list", data => {
        if (data.error === "Matches not found") {
            TweenMax.set('.s-3__show-more', {
                autoAlpha: 0
            });
            return
        }
        if (data.length <= 4) {
            TweenMax.to('.s-3__show-more', .6, {
                autoAlpha: 0
            });
        }
        data.forEach((e, i) => {
            if (i > 3) {
                return false
            }
            let gameObj = e;
            matchesRate(gameObj.mid, gameObj.away, gameObj.awayLogo, gameObj.home, gameObj.homeLogo, gameObj.kickoff, gameObj.league, gameObj.odd1, gameObj.odd2, gameObj.oddx);
        });
    });
    var linkToLeon;
    $('#registration__sign-up__form').submit(e => {
        e.preventDefault();
        $(e.currentTarget).find('[name="betTime"]').val(Date.parse(Date()));
        let firstName = $(e.currentTarget).find('[name="firstName"]').val();
        let lastName = $(e.currentTarget).find('[name="secondName"]').val();
        let phoneNumber = $(e.currentTarget).find('[name="phoneNumber"]').val();
        phoneNumber = phoneNumber.split(' ').splice(1, 4).join();
        let email = $(e.currentTarget).find('[name="email"]').val();
        let checkbox = $(e.currentTarget).find('[name="rules"]').prop('checked');
        if(firstName === ''){
            alert('Введите имя');
            return
        }
        if(lastName === ''){
            alert('Введите фамилию');
            return
        }
        if(phoneNumber === ''){
            alert('Введите номер телефона');
            return
        }
        if(email === ''){
            alert('Введите емейл');
            return
        }
        if(checkbox === false){
            alert('Подтвердите, что вам есть 18 лет');
            return
        }
        $.ajax({
            url: '/api/createUser',
            type: 'POST',
            data: $(e.currentTarget).serialize(),
            dataType: 'json',
            cache: false,
            success: function (response) {
                if (response.error) {
                    alert(response.error);
                }
                if (response.success === true) {
                    if (TweenMax.isTweening('.registration__sign-up')) {
                        return
                    }
                    TweenMax.to('.registration__sign-up', .5, {
                        autoAlpha: 0,
                        display: 'none',
                        onComplete: () => {
                            TweenMax.to('.registration__success', .5, {
                                autoAlpha: 1,
                                display: 'flex'
                            });
                        }
                    });
                }
            }
        });
        linkToLeon = 'https://www.leon.ru/registration?' + 'email=' + encodeURIComponent(email) +
            '&phone=' + encodeURIComponent(phoneNumber) + '&fname=' + encodeURIComponent(firstName) +
            '&lname=' + encodeURIComponent(lastName);


    });
    $('#registration__sign-in__form').submit(e => {
        e.preventDefault();
        $(e.currentTarget).find('[name="betTime"]').val(Date.parse(Date()));
        let leonID = $(e.currentTarget).find('[name="leonID"]').val();
        let phoneNumber = $(e.currentTarget).find('[name="phoneNumber"]').val();
        let checkbox = $(e.currentTarget).find('[name="rules"]').prop('checked');
        if(leonID === ''){
            alert('Введите Leon ID');
            return
        }
        if(phoneNumber === ''){
            alert('Введите номер телефона');
            return
        }
        if(checkbox === false){
            alert('Подтвердите, что вам есть 18 лет');
            return
        }
        $.ajax({
            url: '/api/createUser',
            type: 'POST',
            data: $(e.currentTarget).serialize(),
            dataType: 'json',
            cache: false,
            success: response => {
                if (response.error) {
                    alert(response.error);
                }
                if (response.success === true) {
                    if (TweenMax.isTweening('.registration__sign-in')) {
                        return
                    }
                    TweenMax.to('.registration__sign-in', .5, {
                        autoAlpha: 0,
                        display: 'none',
                        onComplete: () => {
                            if (_detectSafari()) {
                                TweenMax.to('.registration__success', .5, {
                                    autoAlpha: 1,
                                    display: '-webkit-flex'
                                });
                            } else {
                                TweenMax.to('.registration__success', .5, {
                                    autoAlpha: 1,
                                    display: 'flex'
                                });
                            }
                        }
                    });
                }
            }
        });
    });
    if (_detectPhone()) {
        $(document).on('touchend', '.registration__success__button', function () {
            if (linkToLeon) {
                window.open(linkToLeon, '_blank')
            } else {
                window.open('https://www.leon.ru/', '_blank')
            }
        });
        $('.s-1__all-matches--click').on('touchend', () => {
            TweenMax.to(window, .6, {
                scrollTo: '#s3'
            });
        });
        $('.s-3__up-btn--click').on('touchend', () => {
            TweenMax.to(window, .6, {
                scrollTo: {y: 0, autoKill: false}
            });
        });
        $('.registration__sign-up__button').on('touchend', e => {
            e.preventDefault();
            if($('#registration__sign-up__form')[0].checkValidity()){
                $('#registration__sign-up__form').submit();
            } else {
                if(_detectApple()){
                    if(_detectIOS11()){
                        $('#registration__sign-up__form')[0].reportValidity()
                    } else {
                        $('#registration__sign-up__form').submit();
                    }
                } else {
                    $('#registration__sign-up__form')[0].reportValidity()
                }
            }
        });
        $('.registration__sign-in__button').on('touchend', e => {
            e.preventDefault();
            if($('#registration__sign-in__form')[0].checkValidity()){
                $('#registration__sign-in__form').submit();
            } else {
                if(_detectApple()){
                    if(_detectIOS11()){
                        $('#registration__sign-in__form')[0].reportValidity()
                    } else {
                        $('#registration__sign-in__form').submit();
                    }
                } else {
                    $('#registration__sign-in__form')[0].reportValidity()
                }
            }
        });
        $('.s-3__show-more').on('touchend', () => {
            let itemsL = $('.s-3__daily-rate').length;
            $.getJSON("/api/match/list", function (data) {
                if (data.length > itemsL) {
                    let heightCount = data.length - itemsL;
                    if (heightCount > 4) {
                        heightCount = 4;
                    }
                    let heightDaily = parseInt(window.getComputedStyle($('.s-3__daily-rate').get(1), null).getPropertyValue('margin-top'), 10) + $('.s-3__daily-rate').height();
                    TweenMax.to('.s-3__daily-rate__container', .6, {
                        height: '+=' + (heightDaily * heightCount),
                        onComplete: () => {
                            TweenMax.to('.s-3__daily-rate', .6, {
                                autoAlpha: 1,
                                display: 'flex',
                                x: '0%'
                            });
                        }
                    });
                }
                data.forEach((e, i) => {
                    if (i < itemsL) {
                        TweenMax.to('.s-3__show-more', .6, {
                            autoAlpha: 0
                        });
                        return
                    }
                    if (i > itemsL + 3) {
                        return false
                    }
                    let gameObj = e;
                    matchesRate(gameObj.mid, gameObj.away, gameObj.awayLogo, gameObj.home, gameObj.homeLogo, gameObj.kickoff, gameObj.league, gameObj.odd1, gameObj.odd2, gameObj.oddx);
                });
            });
        });
        $('.menu__buttons-item').on('touchend', e => {
            e.preventDefault();
            $('.menu-lines--click').toggleClass('close');
            $('body').toggleClass('scroll-hidden');
            $('.menu').toggleClass('active');
            if ($('.menu').hasClass('active')) {
                menuAnimation();
            } else {
                TweenMax.fromTo('.menu', .6, {
                    autoAlpha: 1,
                    x: '0%'
                }, {
                    autoAlpha: 0,
                    x: '100%'
                });
            }
            let hash = e.currentTarget.hash;
            let posTop = $(hash).offset().top + 1;
            TweenMax.to(window, .6, {
                scrollTo: {y: posTop, autoKill: false}
            });
        });
        function KeyCodeInput(input) {
            let oldValue,
                newValue,
                cyrillicAlphabetAndWhiteSpace = /[А-Яа-я ]/g,
                difference = function (value1, value2) {
                    let output = [];
                    for (let i = 0; i < value2.length; i++) {
                        if (value1[i] !== value2[i]) {
                            output.push(value2[i]);
                        }
                    }
                    return output.join("");
                },
                keyDownHandler = function (e) {
                    oldValue = input.value;
                },
                inputHandler = function (e) {
                    newValue = input.value;
                    let key = difference(oldValue, newValue);
                    if (cyrillicAlphabetAndWhiteSpace.test(key) || e.inputType == "deleteContentBackward") {
                        cyrillicAlphabetAndWhiteSpace.test(key);
                        this.setCustomValidity("");
                    } else {
                        this.setCustomValidity("Только буквы русского алфавита");
                        if(_detectApple()){
                            if(!_detectIOS11()){
                                alert("Только буквы русского алфавита");
                            } else {
                                this.reportValidity();
                            }
                        } else {
                            this.reportValidity();
                        }
                        $(this).val($(this).val().replace(/[^А-Яа-я ]/g, ''));
                    }
                };
            input.addEventListener('keydown', keyDownHandler);
            input.addEventListener('input', inputHandler);
        }
        const inp1 = new KeyCodeInput(document.getElementById('registration__sign-up__first-name'));
        const inp2 = new KeyCodeInput(document.getElementById('registration__sign-up__second-name'));
    } else {
        $(document).on('click', '.registration__success__button', function () {
            if (linkToLeon) {
                window.open(linkToLeon, '_blank')
            } else {
                window.open('https://www.leon.ru/', '_blank')
            }
        });
        $('.s-1__all-matches--click').on('click', () => {
            TweenMax.to(window, .6, {
                scrollTo: '#s3'
            });
        });
        $('.s-3__up-btn--click').on('click', () => {
            TweenMax.to(window, .6, {
                scrollTo: {y: 0, autoKill: false}
            });
        });
        $('.s-3__show-more').on('click', () => {
            let itemsL = $('.s-3__daily-rate').length;
            $.getJSON("/api/match/list", function (data) {
                if (data.length > itemsL) {
                    let heightCount = data.length - itemsL;
                    if (heightCount > 4) {
                        heightCount = 4;
                    }
                    let heightDaily = parseInt(window.getComputedStyle($('.s-3__daily-rate').get(1), null).getPropertyValue('margin-top'), 10) + $('.s-3__daily-rate').height();
                    TweenMax.to('.s-3__daily-rate__container', .6, {
                        height: '+=' + (heightDaily * heightCount),
                        onComplete: () => {
                            TweenMax.to('.s-3__daily-rate', .6, {
                                autoAlpha: 1,
                                display: 'flex',
                                x: '0%'
                            });
                        }
                    });
                }
                data.forEach((e, i) => {
                    if (i < itemsL) {
                        TweenMax.to('.s-3__show-more', .6, {
                            autoAlpha: 0
                        });
                        return
                    }
                    if (i > itemsL + 3) {
                        return false
                    }
                    let gameObj = e;
                    matchesRate(gameObj.mid, gameObj.away, gameObj.awayLogo, gameObj.home, gameObj.homeLogo, gameObj.kickoff, gameObj.league, gameObj.odd1, gameObj.odd2, gameObj.oddx);
                });
            });
        });
        $('.menu__buttons-item').on('click', e => {
            e.preventDefault();
            $('.menu-lines--click').toggleClass('close');
            $('body').toggleClass('scroll-hidden');
            $('.menu').toggleClass('active');
            if ($('.menu').hasClass('active')) {
                menuAnimation();
            } else {
                TweenMax.fromTo('.menu', .6, {
                    autoAlpha: 1,
                    x: '0%'
                }, {
                    autoAlpha: 0,
                    x: '100%'
                });
            }
            let hash = e.currentTarget.hash;
            let posTop = $(hash).offset().top + 1;
            TweenMax.to(window, .6, {
                scrollTo: {y: posTop, autoKill: false}
            });
        });

        $("#registration__sign-up__first-name, #registration__sign-up__second-name").on("keydown", function (event) {
            // Disallow anything not matching the regex pattern (A to Z uppercase, a to z lowercase and white space)
            // For more on JavaScript Regular Expressions, look here: https://developer.mozilla.org/en-US/docs/JavaScript/Guide/Regular_Expressions
            let englishAlphabetAndWhiteSpace = /[A-Za-z ]/g;
            let cyrillicAlphabetAndWhiteSpace = /[А-Яа-я ]/g;

            // Retrieving the key from the char code passed in event.which
            // For more info on even.which, look here: http://stackoverflow.com/q/3050984/114029
            // let key = String.fromCharCode(event.which);
            let key = event.originalEvent.key;
            let charTest = cyrillicAlphabetAndWhiteSpace.test(key);


            // For the keyCodes, look here: http://stackoverflow.com/a/3781360/114029
            // keyCode == 8  is backspace
            // keyCode == 37 is left arrow
            // keyCode == 39 is right arrow
            // cyrillicAlphabetAndWhiteSpace.test(key) does the matching, that is, test the key just typed against the regex pattern
            if ( event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 39 || charTest ) {
                this.setCustomValidity("");
                return true;
            } else {
                this.setCustomValidity("Только буквы русского алфавита");
                this.reportValidity();
            }

            // If we got this far, just return false because a disallowed key was typed.
            return false;
        });
    }
    if ($(window).width() <= 768) {
        if ($(window).height() > $(window).width()) {
            TweenMax.set('.registration__container, .registration__overlay', {
                height: window.innerHeight
            });
        } else {
            TweenMax.set('.registration__container, .registration__overlay', {
                height: $(window).width()
            });
        }
        $(window).on('resize', function () {
            TweenMax.set('.registration__popup-container', {
                height: window.innerHeight
            });
            if ($(window).height() > $(window).width()) {
                if (window.innerHeight > $('.registration__container').height()) {
                    TweenMax.set('.registration__container, .registration__overlay', {
                        height: window.innerHeight
                    });
                }
            } else {

            }
        });
    }
    if ($(window).width() >= 768) {

    }
    if ($(window).width() >= 1360) {

    }
    if ($(window).width() >= 1583) {
        TweenMax.set($('.s-2__stages-svg'), {
            z: .1
        });
        TweenMax.set($('.s-2__stages-title, .s-2__stages-text'), {
            autoAlpha: 0,
            x: -100,
            z: .1
        });
        TweenMax.set($('.s-2__stages-svg path'), {
            drawSVG: '0%'
        });
        const moveAmbassadors = (right = 1, left = 1, percWinSTandSecST) => {
            let rightPos = right * percWinSTandSecST;
            let leftPos = left * percWinSTandSecST;
            TweenMax.to('.trainer-one', .3, {
                right: rightPos
            });
            TweenMax.to('.trainer-two', .3, {
                left: leftPos
            });
        };
        const ambassadors = () => {
            let winST = $(window).scrollTop();
            let secST = $('.main__s2').offset().top;
            let lastST = $('.s-3__title').offset().top - 85;
            let percWinSTandSecST = winST / secST;
            if (winST >= $(document).height() - $(window).height() - 100) {
                return
            }
            TweenMax.set('.main', {
                pointerEvents: 'none'
            });
            if (percWinSTandSecST >= 1) {
                percWinSTandSecST = 1;
            }
            if (percWinSTandSecST >= .45) {
                $('.trainer-one, .trainer-two').addClass('active-text');
            } else {
                $('.trainer-one, .trainer-two').removeClass('active-text');
            }
            moveAmbassadors(190, 250, percWinSTandSecST);
            TweenMax.to('.main__trainers', .3, {
                top: winST,
                onComplete: () => {
                    TweenMax.set('.main', {
                        pointerEvents: 'all'
                    });
                }
            });
        };
        const piantLines = () => {
            let winST = $(window).scrollTop();
            let secST = $('.main__s2').offset().top;
            if (winST >= secST - 500) {
                TweenMax.staggerTo($('.s-2__stages-svg path'), 1, {
                    drawSVG: '100%'
                }, .4);
                TweenMax.staggerTo($('.s-2__stages-title, .s-2__stages-text'), 1, {
                    autoAlpha: 1,
                    x: 0
                }, .4);
            }
        };
        ambassadors();
        piantLines();
        $(window).on('scroll', e => {
            ambassadors();
            piantLines();
        });
    }
    $.jMaskGlobals = {
        maskElements: 'input,td,span,div',
        dataMaskAttr: '*[data-mask]',
        dataMask: true,
        watchInterval: 300,
        watchInputs: true,
        watchDataMask: false,
        byPassKeys: [9, 16, 17, 18, 36, 37, 38, 39, 40, 91],
        translation: {
            '0': {pattern: /\d/, optional: true},
            '#': {pattern: /\d/, recursive: true},
            'A': {pattern: /[a-zA-Z0-9]/},
            'S': {pattern: /[a-zA-Z]/}
        }
    };

    $('#registration__sign-up__first-name').on("paste", function (e) {
        e.preventDefault();
    });
    $("#registration__sign-up__phone, #registration__sign-in__phone").mask("+7 (900) 00 00 000");

    $("#registration__sign-up__phone, #registration__sign-in__phone, #registration__sign-in__first-name").keydown(function (e) {
        // Allow: backspace, delete, tab, escape, enter and .
        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
            // Allow: Ctrl+A, Command+A
            (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
            // Allow: home, end, left, right, down, up
            (e.keyCode >= 35 && e.keyCode <= 40)) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    });
    const tmArrows = new TimelineLite();
    tmArrows.staggerTo('.s-1__stages-arrows__svg--animation', .4, {
        opacity: .4,
        ease: Power3.easeInOut
    }, .2).staggerTo('.s-1__stages-arrows__svg--animation', .4, {
        opacity: .1,
        ease: Power3.easeInOut
    }, .2, .4, () => {
        tmArrows.restart();
    });
    const menuAnimation = () => {
        TweenMax.killTweensOf('.menu__buttons, .menu__content');
        TweenMax.fromTo('.menu__buttons', .6, {
            autoAlpha: 0,
            x: '50%'
        }, {
            autoAlpha: 1,
            x: '0%',
            delay: .5
        });
        TweenMax.fromTo('.menu__content', .6, {
            autoAlpha: 0,
            x: '50%'
        }, {
            autoAlpha: 1,
            x: '0%',
            delay: 1
        });
    };
    const menuBtnWatch = () => {
        const winST = $(window).scrollTop();
        const sectionsWatch = $('.main__section');
        const btns = $('.menu__buttons-item');
        sectionsWatch.each((i, e) => {
            let sectionsTop = $(e).offset().top;
            let sectionsBottom = sectionsTop + $(e).height();
            if (winST >= sectionsTop && winST <= sectionsBottom) {
                btns.removeClass('active');
                $('.menu__buttons-item[href="#' + $(e).attr('id') + '"]').addClass('active');
            }
        });
    };
    menuBtnWatch();
    $(window).on('scroll', e => {
        menuBtnWatch();
    });
    $(document).on('click', '.registration__overlay, .registration__information-item__second-change-team, .registration__close__container', () => {
        if ($(window).width() <= 768) {
            $('.menu-lines').toggleClass('hide');
        }
        $('body').toggleClass('scroll-hidden');
        // if ($(window).width() <= 1360) {
        //     $('body').toggleClass('scroll-hidden');
        // }
        if ($('.registration__success').is(':visible')) {
            TweenMax.to('.registration__popup-container', .5, {
                autoAlpha: 0,
                onComplete: () => {
                    TweenMax.set('.registration__success', {
                        autoAlpha: 0,
                        display: 'none'
                    });
                    if (_detectSafari()) {
                        TweenMax.set('.registration__choose', {
                            autoAlpha: 1,
                            display: '-webkit-flex'
                        });
                    } else {
                        TweenMax.set('.registration__choose', {
                            autoAlpha: 1,
                            display: 'flex'
                        });
                    }
                }
            });
        } else {
            TweenMax.to('.registration__popup-container', .5, {
                autoAlpha: 0
            });
        }
    });
    $(document).on('click', '.coeff--click', e => {
        TweenMax.to('.registration__popup-container', .5, {
            autoAlpha: 1
        });
        $('body').toggleClass('scroll-hidden');
        if ($(window).width() <= 768) {
            $('.menu-lines').toggleClass('hide');
        }
        // if ($(window).width() <= 1360) {
        //     $('body').toggleClass('scroll-hidden');
        // }
        changeRegForm($(e.currentTarget));
    });
    const changeRegForm = (target) => {
        let data = target.parent().parent().data();
        let odd = target.data('odd');
        let date = new Date(data.kickoff);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let hours = date.getHours() + 1;
        let minutes = date.getMinutes();
        let country = data.league.split(' ')[0];
        let betPrice;
        let information_item;
        $('[name="matchID"]').val(data.mid);
        if (odd === 1) {
            $('[name="odd"]').val('odd1');
            betPrice = Math.round(data.odd1 * 200);
            information_item = `<div class="registration__information-item__second-title">Ставлю на выигрыш команды</div>
                                        <div class="registration__information-item__second-name">${data.away}</div>
                                        <img class="registration__information-item__second-image" src="https://${data.awayLogo}">`;
        }
        if (odd === 'x') {
            $('[name="odd"]').val('oddx');
            betPrice = Math.round(data.oddx * 200);
            information_item = `<div class="registration__information-item__second-title">Ставлю на</div>
                                        <div class="registration__information-item__second-name">ничью</div>
                                        <div>
                                            <img class="registration__information-item__second-image" src="https://${data.awayLogo}">
                                            <img class="registration__information-item__second-image" src="https://${data.homeLogo}">
                                        </div>`;
        }
        if (odd === 2) {
            $('[name="odd"]').val('odd2');
            betPrice = Math.round(data.odd2 * 200);
            information_item = `<div class="registration__information-item__second-title">Ставлю на выигрыш команды</div>
                                        <div class="registration__information-item__second-name">${data.home}</div>
                                        <img class="registration__information-item__second-image" src="https://${data.homeLogo}">`;
        }
        let informationTemplate = `<div class="registration__information-item registration__information-item__first">
                                        <div class="registration__information-item__col">
                                            <div class="registration__information-item__row title">
                                                Команда
                                            </div>
                                            <div class="registration__information-item__row">
                                                <span class="medium">${data.away}</span>
                                                <span class="regular">(${country})</span>
                                            </div>
                                            <div class="registration__information-item__row">
                                                <span class="medium">${data.home}</span>
                                                <span class="regular">(${country})</span>
                                            </div>
                                            <div class="registration__information-item__row">
                                                <span class="regular">Начало матча:</span>
                                                <span class="medium">${hours + ':' + minutes} (${day + '.' + month})</span>
                                            </div>
                                        </div>
                                        <div class="registration__information-item__col">
                                            <div class="registration__information-item__row title">
                                                Коэф
                                            </div>
                                            <div class="registration__information-item__row">
                                                <span class="medium">П. ${data.odd1}</span>
                                            </div>
                                            <div class="registration__information-item__row">
                                                <span class="medium">П. ${data.oddx}</span>
                                            </div>
                                            <div class="registration__information-item__row">
                                                <span class="medium">Н. ${data.odd2}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="registration__information-item registration__information-item__second">
                                        ${information_item}
                                        <div class="registration__information-item__second-change-team">Изменить</div>
                                    </div>
                                    <div class="registration__information-item registration__information-item__third">
                                        <p class="registration__information-item__third-text">Возможный выигрыш:</p>
                                        <p class="registration__information-item__third-price">${betPrice}₽</p>
                                    </div>`;
        $('.registration__information').html(informationTemplate);
    };
    $('.registration__choose__sign-up').on('click', () => {
        if (TweenMax.isTweening('.registration__choose')) {
            return
        }
        TweenMax.to('.registration__choose', .5, {
            autoAlpha: 0,
            display: 'none',
            onComplete: () => {
                if (_detectSafari()) {
                    TweenMax.to('.registration__sign-up', .5, {
                        autoAlpha: 1,
                        display: '-webkit-flex'
                    });
                } else {
                    TweenMax.to('.registration__sign-up', .5, {
                        autoAlpha: 1,
                        display: 'flex'
                    });
                }
            }
        });
    });
    $('.registration__choose__sign-in').on('click', () => {
        if (TweenMax.isTweening('.registration__choose')) {
            return
        }
        TweenMax.to('.registration__choose', .5, {
            autoAlpha: 0,
            display: 'none',
            onComplete: () => {
                if (_detectSafari()) {
                    TweenMax.to('.registration__sign-in', .5, {
                        autoAlpha: 1,
                        display: '-webkit-flex'
                    });
                } else {
                    TweenMax.to('.registration__sign-in', .5, {
                        autoAlpha: 1,
                        display: 'flex'
                    });
                }
            }
        });
    });
    $('.registration__arrow-back__container').on('click', () => {
        if (TweenMax.isTweening('.registration__sign-up') || TweenMax.isTweening('.registration__sign-in')) {
            return
        }
        const signVisible = $('.registration__sign-up').is(':visible') ? $('.registration__sign-up') : $('.registration__sign-in')
        TweenMax.to(signVisible, .5, {
            autoAlpha: 0,
            display: 'none',
            onComplete: () => {
                if (_detectSafari()) {
                    TweenMax.to('.registration__choose', .5, {
                        autoAlpha: 1,
                        display: '-webkit-flex'
                    });
                } else {
                    TweenMax.to('.registration__choose', .5, {
                        autoAlpha: 1,
                        display: 'flex'
                    });
                }
            }
        });
    });
    $('.registration__sign-up__input, .registration__sign-in__input').keyup((e) => {
        if ($(e.currentTarget).val() === '') {
            $(e.currentTarget).removeClass('val');
        } else {
            $(e.currentTarget).addClass('val');
        }
    });
    $('.menu-lines--click').on('click', e => {
        $(e.currentTarget).toggleClass('close');
        $('body').toggleClass('scroll-hidden');
        $('.menu').toggleClass('active');
        if ($('.menu').hasClass('active')) {
            menuAnimation();
            TweenMax.fromTo('.menu', .6, {
                autoAlpha: 0,
                x: '100%'
            }, {
                autoAlpha: 1,
                x: '0%'
            });
        } else {
            TweenMax.fromTo('.menu', .6, {
                autoAlpha: 1,
                x: '0%'
            }, {
                autoAlpha: 0,
                x: '100%'
            });
        }
    });
    if (_detectApple()) {
        if(_detectIOS11()){
            $('input').on('focus', e => {
                stopBodyScrolling(true);
            }).on('focusout', e => {
                stopBodyScrolling(false);
            });
        }
        if($('.registration__popup-container').css('visibility') === 'visible') {
            document.ontouchmove = function (e) {
                if ($('body').hasClass('scroll-hidden')) {
                    e.preventDefault();
                } else {
                    return true;
                }
            };
        }
    }
    function stopBodyScrolling(bool) {
        if (bool === true) {
            document.body.addEventListener("touchmove", freezeVp, false);
        } else {
            document.body.removeEventListener("touchmove", freezeVp, false);
        }
    }

    var freezeVp = function (e) {
        e.preventDefault();
    };
});
const headerRate = (mid, home, homeLogo, away, awayLogo, kickoff, league, odd1, odd2, oddx) => {
    let date = new Date(kickoff);
    let day = ('0' + date.getDate()).slice(-2);
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let hours = ('0' + (date.getHours() + 1)).slice(-2);
    let minutes = date.getMinutes() === 0 ? '00' : date.getMinutes();
    let country = league.split(' ')[0];
    let template = `<div class="s-1__matches">
                        <div class="s-1__team-left">
                            <div class="s-1__team-title text-right">
                                <div class="s-1__team-name">${away}</div>
                                <div class="s-1__team-from">${country}</div>
                            </div>
                            <div class="s-1__team-logo">
                                <img src="https://${awayLogo}" alt="${away}">
                            </div>
                        </div>
                        <div class="s-1__vs">
                            <div class="s-1__date"><b>Дата:</b> <span class="s-1__date--text">${day + '.' + month}</span></div>
                            <div class="s-1__time"><b>Время:</b> <span class="s-1__time--text">${hours + ':' + minutes}</span></div>
                        </div>
                        <div class="s-1__team-right">
                            <div class="s-1__team-logo">
                                <img src="https://${homeLogo}" alt="${home}">
                            </div>
                            <div class="s-1__team-title text-left">
                                <div class="s-1__team-name">${home}</div>
                                <div class="s-1__team-from">${country}</div>
                            </div>
                        </div>
                    </div>
                    <div class="s-1__coeff">
                        <div class="s-1__left-coeff coeff--click" data-odd="1">
                            <div class="s-1__coeff-title">Победа</div>
                            <div class="s-1__coeff-num">коэф: <span class="coeff-number s-1__coeff-num--change">${odd1}</span></div>
                            <div class="s-1__left-coeff--hover">
                                <div class="s-1__left-coeff--move">
                                    <div class="s-1__left-coeff--skew"></div>
                                    <span class="s-1__left-coeff-title">Возможный выигрыш:</span>
                                    <span class="s-1__left-coeff-summ">${Math.round(odd1 * 200)}₽</span>
                                </div>
                            </div>
                        </div>
                        <div class="s-1__draw-coeff coeff--click" data-odd="x">
                            <div class="s-1__coeff-title">Ничья</div>
                            <div class="s-1__coeff-num">коэф: <span class="coeff-number s-1__coeff-num--change">${oddx}</span></div>
                            <div class="s-1__draw-coeff--hover">
                                <div class="s-1__draw-coeff--move">
                                    <div class="s-1__draw-coeff--skew"></div>
                                    <span class="s-1__draw-coeff-title">Возможный <br> выигрыш:</span>
                                    <span class="s-1__draw-coeff-summ">${Math.round(oddx * 200)}₽</span>
                                </div>
                            </div>
                        </div>
                        <div class="s-1__right-coeff coeff--click" data-odd="2">
                            <div class="s-1__coeff-title">Победа</div>
                            <div class="s-1__coeff-num">коэф: <span class="coeff-number s-1__coeff-num--change">${odd2}</span></div>
                            <div class="s-1__right-coeff--hover">
                                <div class="s-1__right-coeff--move">
                                    <div class="s-1__right-coeff--skew"></div>
                                    <span class="s-1__right-coeff-title">Возможный выигрыш:</span>
                                    <span class="s-1__right-coeff-summ">${Math.round(odd2 * 200)}₽</span>
                                </div>
                            </div>
                        </div>
                    </div>`;
    $('.s-1__daily-rate').data({
        mid,
        away,
        awayLogo,
        home,
        homeLogo,
        kickoff,
        league,
        odd1,
        odd2,
        oddx
    }).append(template);
};
const matchesRate = (mid, home, homeLogo, away, awayLogo, kickoff, league, odd1, odd2, oddx) => {
    let date = new Date(kickoff);
    let day = ('0' + date.getDate()).slice(-2);
    let month = ('0' + (date.getMonth() + 1)).slice(-2);
    let hours = ('0' + (date.getHours() + 1)).slice(-2);
    let minutes = date.getMinutes() === 0 ? '00' : date.getMinutes();
    let country = league.split(' ')[0];
    let template = `<div class="s-3__daily-rate">
            <div class="s-3__matches">
                <div class="s-3__team-left">
                    <div class="s-3__team-title text-right">
                        <div class="s-3__team-name">${away}</div>
                        <div class="s-3__team-from">${country}</div>
                    </div>
                    <div class="s-3__team-logo">
                        <img src="https://${awayLogo}" alt="${away}">
                    </div>
                </div>
                <div class="s-3__vs">
                    <div class="s-3__date"><b>Дата:</b> <span class="s-3__date--text">${day + '.' + month}</span></div>
                    <div class="s-3__time"><b>Время:</b> <span class="s-3__time--text">${hours + ':' + minutes}</span></div>
                </div>
                <div class="s-3__team-right">
                    <div class="s-3__team-logo">
                        <img src="https://${homeLogo}" alt="${home}">
                    </div>
                    <div class="s-3__team-title text-left">
                        <div class="s-3__team-name">${home}</div>
                        <div class="s-3__team-from">${country}</div>
                    </div>
                </div>
            </div>
            <div class="s-3__coeff">
                <div class="s-3__left-coeff coeff--click" data-odd="1">
                    <div class="s-3__coeff-title">Победа</div>
                    <div class="s-3__coeff-num">коэф: <span class="coeff-number s-3__coeff-num--change">${odd1}</span></div>
                    <div class="s-3__left-coeff--hover">
                        <div class="s-3__left-coeff--move">
                            <div class="s-3__left-coeff--skew"></div>
                            <span class="s-3__left-coeff-title">Возможный выигрыш:</span>
                            <span class="s-3__left-coeff-summ">${Math.round(odd1 * 200)}₽</span>
                        </div>
                    </div>
                </div>
                <div class="s-3__draw-coeff coeff--click" data-odd="x">
                    <div class="s-3__coeff-title">Ничья</div>
                    <div class="s-3__coeff-num">коэф: <span class="coeff-number s-3__coeff-num--change">${oddx}</span></div>
                    <div class="s-3__draw-coeff--hover">
                        <div class="s-3__draw-coeff--move">
                            <div class="s-3__draw-coeff--skew"></div>
                            <span class="s-3__draw-coeff-title">Возможный <br> выигрыш:</span>
                            <span class="s-3__draw-coeff-summ">${Math.round(oddx * 200)}₽</span>
                        </div>
                    </div>
                </div>
                <div class="s-3__right-coeff coeff--click" data-odd="2">
                    <div class="s-3__coeff-title">Победа</div>
                    <div class="s-3__coeff-num">коэф: <span class="coeff-number s-3__coeff-num--change">${odd2}</span></div>
                    <div class="s-3__right-coeff--hover">
                        <div class="s-3__right-coeff--move">
                            <div class="s-3__right-coeff--skew"></div>
                            <span class="s-3__right-coeff-title">Возможный выигрыш:</span>
                            <span class="s-3__right-coeff-summ">${Math.round(odd2 * 200)}₽</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>`;
    $('.s-3__daily-rate__container').append(template);
    $($('.s-3__daily-rate').get($('.s-3__daily-rate').length - 1)).data({
        mid,
        away,
        awayLogo,
        home,
        homeLogo,
        kickoff,
        league,
        odd1,
        odd2,
        oddx
    });
    if ($('.s-3__daily-rate').length <= 4) {
        TweenMax.to('.s-3__daily-rate', .4, {
            autoAlpha: 1,
            display: 'flex',
            x: '0%',
            onComplete: () => {
                TweenMax.set('.s-3__daily-rate__container', {
                    height: $('.s-3__daily-rate__container').height()
                })
            }
        });
    }
};
function _detectSafari() {
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}
function _detectApple() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        document.querySelector('body').classList.add('ios-mobile');
        return true
    } else {
        return false
    }
}
function _detectIOS11() {
    if (/OS 11/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}
function _detectPhone() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}
